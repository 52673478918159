import { Link } from "react-router-dom";
import { WebsiteInformation } from "./data";

export const Conversation = {
	"Автостекла": {
		message: "Что вас интересует?",
		options: ["Автостекла.Замена", "Автостекла.Ремонт", "Автостекла.Продажа", "Автостекла.Гарантия"],
		value: "Автостекла",
		client: "Меня интересует сервис автостекол"
	},
	"Автостекла.Ремонт": {
		message: ["Стоимость ремонта сколов стоит от 400 рублей. Ремонт трещин от 30 рублей за 1 см.", <br/>, <b>РЕКОМЕНДАЦИЯ</b>, " - Сразу после появления скола или трещины нужно заклеить место повреждения прозрачным скотчем, чтобы защитить его от попадания влаги и пыли.", <br/>, "Использовать щетки стеклоочистителя только в крайнем случае, чтобы место повреждения не забилось мелким мусором.", <br/>, "Не включать обогрев и обдув переднего стекла, чтобы разница температуры внутри и снаружи машины не спровоцировала увеличение повреждения.", <br/>, "И как можно скорее записаться на ремонт, пока повреждение ещё свежее и не увеличилось в размерах."],
		options: [],
		value: "Ремонт",
		client: "Меня интересует ремонт автостекол"
	},
	"Автостекла.Замена": {
		message: ["Было разбито лобовое стекло? Не откладывайте ремонт на потом! Наша компания предлагает профессиональную замену и установку автомобильных стекол любых марок и моделей. Узнать цены можно в ", <Link to="/services">Усгуги</Link>, "."],
		options: [],
		value: "Замена",
		client: "Меня интересует замена автостекол"
	},
	"Автостекла.Продажа": {
		message: "Прожажа лобового стекла без установки - в день обращения.",
		options: [],
		value: "Продажа",
		client: "Я хочу купить у вас автостекло"
	},
	"Автостекла.Гарантия": {
		message: "Гарантия на работу по установке автостекла на 2 года.",
		options: [],
		value: "Гарантия",
		client: "Меня интересует гарантия"
	},
	"Автостекла.Установка": {
		message: `У вас есть свое автомобильное стекло, но не знаете где произвести замену? Мы вам поможем! Наш установочный центр примет вас без очереди! Стоимость установки лобового стекла стоит ${WebsiteInformation.prices.changeWindshield} рублей.`,
		options: [],
		value: "Установка без покупки стекла",
		client: "У меня есть лобовое стекло и мне нужно только установку"
	},
	// 
	"Полировка": {
		message: "Хотите, чтобы ваш автомобиль сиял как новый? Выберите сервис полировки.",
		options: ["Полировка.Стекла", "Полировка.Фар", "Полировка.Кузова"],
		value: "Полировка",
		client: "Меня интересует сервис полировки"
	},
	"Полировка.Стекла": {
		message: `Забудьте о тусклых и мутных стеклах - доверьте нам свой автомобиль, и вы не пожалеете! Полировка стекла стоит ${WebsiteInformation.prices.polishWindow} рублей.`,
		options: [],
		value: "Стекла",
		client: "Меня интересует полировка стекла"
	},
	"Полировка.Фар": {
		message: `Яркий свет для яркой дороги - полировка фар с нами! Цена: ${WebsiteInformation.prices.polishHeadlights} рублей.`,
		options: [],
		value: "Фар",
		client: "Меня интересует полировка фар"
	},
	"Полировка.Кузова": {
		message: `Придайте своему автомобилю новый блеск! Цена: ${WebsiteInformation.prices.polishBody} рублей.`,
		options: [],
		value: "Кузова",
		client: "Меня интересует полировка кузова"
	},
	// 
	"Бронирование": {
		message: `Наша компания предлагает услуги по бронированию фар, которые защитят их от царапин, сколов, и других повреждений. Бронирование фар стоит ${WebsiteInformation.prices.foilHeadlights} рублей.`,
		options: [],
		value: "Бронирование",
		client: "Меня интересует бронирование"
	},
	// 
	"Тонировка": {
		message: ["Установка пленки производится нашей опытной командой профессионалов быстро и качественно, что гарантирует максимальный комфорт и удовлетворение от использования услуги. Узнать цены можно в ", <Link to="/services">Усгуги</Link>, "."],
		options: [],
		value: "Тонировка",
		client: "Меня интересует сервис тонировки"
	}
}